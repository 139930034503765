import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_DetaiNews } from './typesIndex';

// The initial state of the App container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  detailNews: null,
};

export const GET_DETAIL_NEWS = createRoutine('DetailNews/getDetailNews');

const DetailNewsSlice = createSlice({
  name: 'detailNews',
  initialState,
  extraReducers: {
    [HYDRATE]: (state: ContainerState, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.detailNews,
    }),

    [GET_DETAIL_NEWS.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_DETAIL_NEWS.SUCCESS]: (state, action: PayloadAction<I_DetaiNews>) => {
      state.detailNews = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_DETAIL_NEWS.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
});

export const { actions, reducer, name: sliceKey } = DetailNewsSlice;

export default reducer;
