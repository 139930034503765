/* eslint-disable @typescript-eslint/no-unused-vars */
// import { BaseXHR } from '@utils/axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_DETAIL_NEWS } from './slice';

export function* getDetailNews({ payload }: any) {
  try {
    const { slug, id } = payload;
    const { data } = yield call(
      BaseXHR.$get,
      `/api/news/detail/${slug || id}`,
      undefined,
      {
        headers: {
          SiteCode: 'SunParadiseLandPhuQuoc',
        },
      },
    );
    yield put(GET_DETAIL_NEWS.success(data));
  } catch (err) {
    yield put(GET_DETAIL_NEWS.failure());
  }
}

const detailNewsSagas: any = [
  takeLatest(GET_DETAIL_NEWS.TRIGGER, getDetailNews),
];

export default detailNewsSagas;
