import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_DetailDestination } from './typesIndex';

export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  detailDestination: null,
};

export const GET_DETAIL_DESTINATION = createRoutine(
  'DetailDestination/getDetailDestination',
);

const DetailDestinationSlice = createSlice({
  name: 'detailDestination',
  initialState,
  extraReducers: {
    [HYDRATE]: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.detailDestination,
    }),

    [GET_DETAIL_DESTINATION.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_DETAIL_DESTINATION.SUCCESS]: (
      state,
      action: PayloadAction<I_DetailDestination>,
    ) => {
      state.detailDestination = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_DETAIL_DESTINATION.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
});

export const { actions, reducer, name: sliceKey } = DetailDestinationSlice;

export default reducer;
