import Link, { LinkProps } from 'next/link';
import { memo, ReactElement } from 'react';

interface Props {
  href?: LinkProps['href'] | string; // `href` có thể là undefined
  children: ReactElement;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  className?: string;
}

const LinkInOutApp = memo(
  ({ href, children, className, onMouseOver, onMouseOut }: Props) => {
    if (!href) {
      // Nếu không có href, render thẻ div thay vì liên kết
      return (
        <div
          className={className}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          {children}
        </div>
      );
    }

    return typeof href === 'string' &&
      (href.indexOf('http://') === 0 || href.indexOf('https://') === 0) ? (
      <a
        target="_blank"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={className}
        rel="noreferrer"
        href={href}
      >
        {children}
      </a>
    ) : (
      <Link
        href={href}
        className={className}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        {children}
      </Link>
    );
  },
);

export default LinkInOutApp;
