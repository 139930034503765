import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_DetailPark } from './typesIndex';

// The initial state of the App container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  detailPark: null,
};

export const GET_DETAIL_PARK = createRoutine('DetailPark/getDetailPark');

const DetailParkSlice = createSlice({
  name: 'detailPark',
  initialState,
  extraReducers: {
    [HYDRATE]: (state: ContainerState, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.detailPark,
    }),

    [GET_DETAIL_PARK.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_DETAIL_PARK.SUCCESS]: (state, action: PayloadAction<I_DetailPark>) => {
      state.detailPark = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_DETAIL_PARK.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
});

export const { actions, reducer, name: sliceKey } = DetailParkSlice;

export default reducer;
