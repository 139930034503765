import { take, fork, select, put, call, takeLatest } from 'redux-saga/effects';
import { isServer } from '@utils/helper';
import { BaseXHR } from '@utils/axios';
import { GET_AWARD, GET_HOME, GET_NEWS } from './slice';

export function* watchAndLog() {
  while (true) {
    try {
      const styleConsole =
        'background: yellow; font-weight: bold; color: #40a9ff8c;';
      const action = yield take('*');
      const state = yield select();
      if (action.type !== '@@router/LOCATION_CHANGE') {
        console.group(action.type);
        console.log('%cBefore State', styleConsole, state);
        console.info('%cDispatching', styleConsole, action);
        console.log('%cNext State', styleConsole, state);
        console.groupEnd();
      }
    } catch {}
  }
}

export function* getHome() {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      '/api/home?SiteCode=SunParadiseLand',
      undefined,
    );
    yield put(GET_HOME.success(data));
  } catch (err) {
    yield put(GET_HOME.failure());
  }
}
export function* getAward() {
  try {
    const { data } = yield call(BaseXHR.$get, '/api/award/listing', undefined);
    yield put(GET_AWARD.success(data.data));
  } catch (err) {
    yield put(GET_AWARD.failure());
  }
}
export function* getNews() {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      '/api/news/listing?SiteCode=SunParadiseLand',
      undefined,
    );
    yield put(GET_NEWS.success(data));
  } catch (err) {
    yield put(GET_NEWS.failure());
  }
}

const homeSagas: any = [
  takeLatest(GET_HOME.TRIGGER, getHome),
  takeLatest(GET_AWARD.TRIGGER, getAward),
  takeLatest(GET_NEWS.TRIGGER, getNews),
];

if (!isServer && process.env.NODE_ENV === 'development')
  homeSagas.push(fork(watchAndLog));

export default homeSagas;
