import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@type/index';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.destination || initialState;

export const selectIsLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectIsError = createSelector(
  [selectDomain],
  state => state.error,
);

export const selectIsSuccess = createSelector(
  [selectDomain],
  state => state.success,
);

export const selectDestination = createSelector(
  [selectDomain],
  state => state.destination || undefined,
);

export const selectCategoriesDestination = createSelector(
  [selectDomain],
  state => state.category || undefined,
);
export const selectBannerDestination = createSelector(
  [selectDomain],
  state => state.banner || undefined,
);
