/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_DETAIL_HOTEL, GET_HOTELLIST_DETAIL } from './slice';

export function* getDetailHotel({ payload }: any) {
  try {
    const { SiteCode, slug, id } = payload;
    const { data } = yield call(
      BaseXHR.$get,
      `/api/explore/detail/${slug || id}`,
      undefined,
      {
        headers: {
          SiteCode,
        },
      },
    );

    yield put(GET_DETAIL_HOTEL.success(data));
  } catch (err) {
    yield put(GET_DETAIL_HOTEL.failure());
  }
}
export function* getHotelDestination({ payload }: any) {
  try {
    const { SiteCode, slug, id } = payload;
    const { data } = yield call(
      BaseXHR.$get,
      `/api/explore/relate/${slug || id}?SiteCode=${SiteCode}`,
      undefined,
      {},
    );

    yield put(GET_HOTELLIST_DETAIL.success(data));
  } catch (err) {
    yield put(GET_HOTELLIST_DETAIL.failure());
  }
}

const detailHotelSagas: any = [
  takeLatest(GET_DETAIL_HOTEL.TRIGGER, getDetailHotel),
  takeLatest(GET_HOTELLIST_DETAIL.TRIGGER, getHotelDestination),
];

export default detailHotelSagas;
