/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_BANNER_EAT, GET_EAT } from './slice';

export function* getEat({ payload }: any) {
  try {
    const { SiteCode, limit } = payload;
    const { data: EatData } = yield call(
      BaseXHR.$get,
      `/api/explore/listing?SiteCode=${SiteCode}&page=1&limit=${
        limit || 10
      }&serviceType=eat_play_buy&category=eat`,
      undefined,
      {},
    );

    const { data: categoryData } = yield call(
      BaseXHR.$get,
      `/api/category/child/eat_play_buy?SiteCode=${SiteCode}`,
      undefined,
      {},
    );
    yield put(
      GET_EAT.success({
        eat: EatData,
        category: categoryData,
      }),
    );
  } catch (err) {
    yield put(GET_EAT.failure());
  }
}

export function* getBannerEat({ payload }: any) {
  try {
    const { SiteCode } = payload;

    const { data } = yield call(
      BaseXHR.$get,
      `/api/banner/detail-by-key/eat`,
      undefined,
      {
        headers: {
          sitecode: SiteCode,
        },
      },
    );

    yield put(GET_BANNER_EAT.success(data));
  } catch (err) {
    yield put(GET_BANNER_EAT.failure());
  }
}

const eatSagas: any = [
  takeLatest(GET_EAT.TRIGGER, getEat),
  takeLatest(GET_BANNER_EAT.TRIGGER, getBannerEat),
];

export default eatSagas;
