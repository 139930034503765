/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_SHOPING, GET_BANNER_SHOPING } from './slice';

export function* getShoping({ payload }: any) {
  try {
    const { SiteCode, limit } = payload;

    const { data: ShopingData } = yield call(
      BaseXHR.$get,
      `/api/explore/listing?SiteCode=${SiteCode}&page=1&limit=${
        limit || 10
      }&serviceType=eat_play_buy&category=buy`,
      undefined,
      {},
    );

    const { data: categoryData } = yield call(
      BaseXHR.$get,
      `/api/category/child/entertainment?SiteCode=${SiteCode}`,
      undefined,
      {},
    );

    yield put(
      GET_SHOPING.success({
        shoping: ShopingData,
        category: categoryData,
      }),
    );
  } catch (err) {
    yield put(GET_SHOPING.failure());
  }
}

export function* getBannerShoping({ payload }: any) {
  try {
    const { SiteCode } = payload;

    const { data } = yield call(
      BaseXHR.$get,
      `/api/banner/detail-by-key/shoping`,
      undefined,
      {
        headers: {
          sitecode: SiteCode,
        },
      },
    );

    yield put(GET_BANNER_SHOPING.success(data));
  } catch (err) {
    yield put(GET_BANNER_SHOPING.failure());
  }
}

const shopingSagas: any = [
  takeLatest(GET_SHOPING.TRIGGER, getShoping),
  takeLatest(GET_BANNER_SHOPING.TRIGGER, getBannerShoping),
];

export default shopingSagas;
