/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_DETAIL_DESTINATION } from './slice';

export function* getDetailDestination({ payload }: any) {
  try {
    const { SiteCode, slug, id } = payload;
    const { data } = yield call(
      BaseXHR.$get,
      `/api/explore/detail/${slug || id}`,
      undefined,
      {
        headers: {
          SiteCode,
        },
      },
    );

    yield put(GET_DETAIL_DESTINATION.success(data));
  } catch (err) {
    yield put(GET_DETAIL_DESTINATION.failure());
  }
}

const detailDestinationSagas: any = [
  takeLatest(GET_DETAIL_DESTINATION.TRIGGER, getDetailDestination),
];

export default detailDestinationSagas;
