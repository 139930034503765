/* eslint-disable no-unused-vars */

import { nanoid } from '@reduxjs/toolkit';
import DOMPurify from 'isomorphic-dompurify';
import { notification, message } from 'antd';

const PHARSE_SECRET_SPL = 'spl-platform';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

function hasKey<O extends object>(
  obj: O,
  key: string | number | symbol,
): key is keyof O {
  return key in obj;
}

export const openNotificationWithIcon = (
  type: NotificationType,
  title: string,
  description?: string,
  duration?: number,
  key?: string,
) => {
  notification[type]({
    ...(key ? { key } : {}),
    message: title,
    description,
    duration: (duration ?? 5) * 1000,
  });
};

/**
 * @param {number} input: number
 * @return number formatted, 100000 => 100,000
 */
export const formatNumber = (number: number): string => {
  const value = `${number}`;
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
};

/**
 * @param func function debounced
 * @param waitFor time debounce
 */
export const debounce = <F extends (...args: unknown[]) => unknown>(
  func: F,
  waitFor: number,
) => {
  const timeout = 0;

  const debounced = (...args: unknown[]) => {
    clearTimeout(timeout);
    setTimeout(() => func(...args), waitFor);
  };

  return debounced;
};

export const isServer = typeof window === 'undefined';

export const toggleItemArrs = <T extends string | number>(
  arrs: T[],
  item: T,
) => {
  const listXor: T[] = arrs.some(el => el === item)
    ? arrs.filter(el => el !== item)
    : [...arrs, item];
  return listXor as T[];
};

export const convertLinkVideoToPlayable = src => {
  let urlConverted = src;
  const linkYoutube = src.match(
    /(?:youtube\.com|youtu\.be|youtube-nocookie\.com)\/(?:watch\?(?:.*&)?v=|v\/|u\/|embed\/?)?(videoseries\?list=(?:.*)|[\w-]{11}|\?listType=(?:.*)&list=(?:.*))(?:.*)/i,
  );
  const linkVimeo = src.match(/^.+vimeo.com\/(?:\/)?([\d]+)(.*)?/);
  if (linkYoutube) {
    const videoId = encodeURIComponent(linkYoutube[1]);
    urlConverted = `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&autoplay=1&controls=0&amp;showinfo=0&&amp;modestbranding=1`;
  }
  if (linkVimeo) {
    const videoId = encodeURIComponent(linkVimeo[1]);
    urlConverted = `https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&autopause=0&mute=1&background=1`;
  }
  return urlConverted;
};

export const onDisabledReactDevtoolOnProduction = () => {
  if (!isServer && process.env.NODE_ENV === 'production') {
    // Ensure the React Developer Tools global hook exists
    const _window = window as any;
    if (typeof _window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'object') return;

    // Replace all global hook properties with a no-op function or a null value
    Object.keys(_window.__REACT_DEVTOOLS_GLOBAL_HOOK__).forEach(prop => {
      if (prop === 'renderers') {
        // prevents console error when dev tools try to iterate of renderers
        _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
      } else {
        _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
          typeof _window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function'
            ? Function.prototype
            : null;
      }
    });
  }
};

export const removeScripts = (str: string) => {
  const result = str ? str.replace(/\n/g, '<br />') : '';
  return DOMPurify.sanitize(result, {
    ALLOWED_TAGS: [
      'iframe',
      'a',
      'p',
      'br',
      'b',
      'i',
      'u',
      'strong',
      'em',
      'ul',
      'ol',
      'li',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'table',
      'tr',
      'tbody',
      'thead',
      'tfoot',
      'td',
      'img',
      'blockquote',
      'span',
    ],
    ADD_ATTR: ['target'],
  });
};

export const encryptText = (mess: string) => {
  const textToChars = (text: string) =>
    text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => `0${Number(n).toString(16)}`.substr(-2);
  const applySaltToChar = code =>
    // eslint-disable-next-line no-bitwise
    textToChars(PHARSE_SECRET_SPL).reduce((a, b) => a ^ b, code);

  return mess
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
};

export const decryptText = (data: string) => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code =>
    textToChars(PHARSE_SECRET_SPL).reduce((a, b) => a ^ b, code);
  return (data.match(/.{1,2}/g) || [])
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
};

export const openMessageWithIcon = (
  type: string,
  content: any,
  duration?: number,
) => {
  // key && message.config({ key });
  if (hasKey(message, type))
    message[type](content, duration && duration > 1 ? duration : 2);
};

/**
 * @return id random
 */
export const generateRandomID = () => nanoid();

export const createQueryString = (name: string, value: string) => {
  const params = new URLSearchParams({});
  params.set(name, value);

  return params.toString();
};
