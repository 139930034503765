import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_Category, I_Destination } from './typesIndex';
import { I_Banner } from '@containers/Eat/typesIndex';

export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  destination: null,
  category: null,
  banner: null,
};

export const GET_DESTINATION = createRoutine('Destination/getDestination');
export const GET_CATEGORIES_DESTINATION = createRoutine(
  'Destination/getCategoriesDestination',
);
export const GET_BANNER_DESTINATION = createRoutine(
  'Destination/getBannerDestination',
);

const DestinationSlice = createSlice({
  name: 'destination',
  initialState,
  extraReducers: {
    [HYDRATE]: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.destination,
    }),

    [GET_DESTINATION.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },

    [GET_DESTINATION.SUCCESS]: (
      state,
      action: PayloadAction<{
        destination: I_Destination;
      }>,
    ) => {
      state.destination = action.payload.destination;
      state.loading = false;
      state.success = true;
      state.error = false;
    },

    [GET_DESTINATION.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    [GET_CATEGORIES_DESTINATION.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },

    [GET_CATEGORIES_DESTINATION.SUCCESS]: (
      state,
      action: PayloadAction<{
        category: I_Category[];
      }>,
    ) => {
      state.category = action.payload.category;
      state.loading = false;
      state.success = true;
      state.error = false;
    },

    [GET_CATEGORIES_DESTINATION.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    [GET_BANNER_DESTINATION.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_BANNER_DESTINATION.SUCCESS]: (
      state,
      action: PayloadAction<I_Banner>,
    ) => {
      state.banner = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_BANNER_DESTINATION.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
});

export const { actions, reducer, name: sliceKey } = DestinationSlice;

export default reducer;
