import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_Award, I_Home, I_News } from './typesIndex';

// The initial state of the App container
export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  home: null,
  award: null,
  news: null,
};

export const GET_HOME = createRoutine('App/getHome');
export const GET_AWARD = createRoutine('App/getPrize');
export const GET_NEWS = createRoutine('App/getNews');

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },

  // extraReducers: builder => {
  //   builder.addCase(
  //     HYDRATE as string,
  //     (_state: ContainerState, action: PayloadAction<any>) =>
  //       action.payload.app,
  //   );
  // },
  extraReducers: {
    [HYDRATE]: (state: ContainerState, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.app,
    }),

    [GET_HOME.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_HOME.SUCCESS]: (state, action: PayloadAction<I_Home>) => {
      state.home = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_HOME.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    [GET_AWARD.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_AWARD.SUCCESS]: (state, action: PayloadAction<I_Award>) => {
      state.award = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_AWARD.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    [GET_NEWS.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_NEWS.SUCCESS]: (state, action: PayloadAction<I_News>) => {
      state.news = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_NEWS.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export const { actions, reducer, name: sliceKey } = appSlice;

export default reducer;
