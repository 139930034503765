/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_BANNER_PLAY, GET_PLAY } from './slice';

export function* getPlay({ payload }: any) {
  try {
    const { SiteCode, limit } = payload;

    const { data: PlayData } = yield call(
      BaseXHR.$get,
      `/api/explore/listing?SiteCode=${SiteCode}&page=1&limit=${
        limit || 10
      }&serviceType=eat_play_buy&category=play`,
      undefined,
      {},
    );

    const { data: categoryData } = yield call(
      BaseXHR.$get,
      `/api/category/child/eat_play_buy?SiteCode=${SiteCode}`,
      undefined,
      {},
    );

    yield put(
      GET_PLAY.success({
        play: PlayData,
        category: categoryData,
      }),
    );
  } catch (err) {
    yield put(GET_PLAY.failure());
  }
}

export function* getBannerPlay({ payload }: any) {
  try {
    const { SiteCode } = payload;

    const { data } = yield call(
      BaseXHR.$get,
      `/api/banner/detail-by-key/play`,
      undefined,
      {
        headers: {
          sitecode: SiteCode,
        },
      },
    );

    yield put(GET_BANNER_PLAY.success(data));
  } catch (err) {
    yield put(GET_BANNER_PLAY.failure());
  }
}

const playSagas: any = [
  takeLatest(GET_PLAY.TRIGGER, getPlay),
  takeLatest(GET_BANNER_PLAY.TRIGGER, getBannerPlay),
];

export default playSagas;
