import { useState, useEffect } from 'react';

const useIsPc = () => {
  const [isPc, setIsPc] = useState(false);

  useEffect(() => {
    const checkIfPc = () => {
      if (typeof window !== 'undefined') {
        setIsPc(window.innerWidth > 769);
      }
    };

    checkIfPc();
    window.addEventListener('resize', checkIfPc);

    return () => {
      window.removeEventListener('resize', checkIfPc);
    };
  }, []);

  return isPc;
};

export default useIsPc;
