import LinkInOutApp from '@components/LinkInOutApp';
import React, { memo } from 'react';
import { SOCIAL } from './constants';

const Footer = memo(() => {
  return (
    <footer>
      <div className="footerTop">
        <img src="/static/img/banner.jpeg" alt="" className="--banner" />
        <img src="/static/img/cloud2.png" alt="" className="--cloud" />
      </div>
      <div className="footerCenter">
        <div className="container-fluid">
          <div className="--item">
            <div className="--name">Popular Cities</div>
            <div className="--listLink">
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
            </div>
          </div>
          <div className="--item">
            <div className="--name">Popular Cities</div>
            <div className="--listLink">
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
              <a href="" className="--itemLink link">
                Những việc cần làm ở Phú Quốc
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBot">
        <div className="--top">
          <div className="container-fluid">
            <div className="--col">
              <div className="--logo">
                <img src="/static/img/logoparadise.png" alt="" />
              </div>
              <div className="--title">Sun Paradise Land</div>
              <div className="--follow">
                <span>Follow Us On</span>
                <div className="--listSocial">
                  {SOCIAL.map((item, i) => (
                    <LinkInOutApp
                      href={item?.link}
                      className="--itemSocial link"
                      key={i}
                    >
                      <i className={item.icon} />
                    </LinkInOutApp>
                  ))}
                </div>
              </div>
            </div>
            <div className="--col">
              <div className="--name">About Us</div>
              <div className="--listMenu">
                <a href="" className="--itemMenu link">
                  About Us
                </a>
                <a href="" className="--itemMenu link">
                  FAQ
                </a>
                <a href="" className="--itemMenu link">
                  Press
                </a>
              </div>
            </div>
            <div className="--col">
              <div className="--name">Top Destinations</div>
              <div className="--listMenu">
                <a href="" className="--itemMenu link">
                  Why Paradise Land?
                </a>
                <a href="" className="--itemMenu link">
                  Sun point
                </a>
                <a href="" className="--itemMenu link">
                  Student Store
                </a>
              </div>
            </div>
            <div className="--col">
              <div className="--name">Đối tác</div>
              <div className="--listMenu">
                <LinkInOutApp
                  href="https://sunworld.vn/"
                  className="--itemMenu link"
                >
                  <> Sun World</>
                </LinkInOutApp>
                <LinkInOutApp
                  href="https://sunhospitality.com.vn/"
                  className="--itemMenu link"
                >
                  <>Sun Hospotality</>
                </LinkInOutApp>
                <LinkInOutApp
                  href="https://sunair.vn/"
                  className="--itemMenu link"
                >
                  <>Sun Air</>
                </LinkInOutApp>
                <LinkInOutApp
                  href="https://visitphuquoc.com.vn/ "
                  className="--itemMenu link"
                >
                  <>Visit Phu Quoc</>
                </LinkInOutApp>
                <LinkInOutApp
                  href="https://sunsettown.com.vn/ "
                  className="--itemMenu link"
                >
                  <>Sunset Town</>
                </LinkInOutApp>
              </div>
            </div>
            <div className="--col">
              <div className="--name">Paradise Land</div>
              <div className="--listMenu">
                <LinkInOutApp
                  href="/SunParadiseLandPhuQuoc"
                  className="--itemMenu link"
                >
                  <> Paradise Land Phú Quốc</>
                </LinkInOutApp>
              </div>
            </div>
          </div>
        </div>
        <div className="--bottom">
          <div className="container-fluid">
            <div className="--copy">
              @ 2024 Sun Paradise Land. All Rights Reserved
            </div>
            <div className="--privacy">
              <a href="" className="--item link">
                Điều khoản{' '}
              </a>
              <a href="" className="--item link">
                {' '}
                Chính sách bảo mật
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
