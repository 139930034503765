/* eslint-disable @typescript-eslint/no-unused-vars */
// import { BaseXHR } from '@utils/axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_DETAIL_PARK } from './slice';

export function* getDetailPark({ payload }: any) {
  try {
    const { data } = yield call(
      BaseXHR.$get,
      `/api/home?SiteCode=${payload.SiteCode}`,
      undefined,
      {
        headers: {
          SiteCode: payload.SiteCode,
        },
      },
    );
    yield put(GET_DETAIL_PARK.success(data));
  } catch (err) {
    yield put(GET_DETAIL_PARK.failure());
  }
}

const detailParkSagas: any = [
  takeLatest(GET_DETAIL_PARK.TRIGGER, getDetailPark),
];

export default detailParkSagas;
