export const SOCIAL = [
  {
    link: 'https://www.facebook.com/tapdoanmattroi',
    icon: 'fa-brands fa-facebook-f',
  },
  {
    link: 'https://www.youtube.com/c/SunGroupOfficial',
    icon: 'fa-brands fa-youtube',
  },
  {
    link: 'https://www.tiktok.com/@sungroupofficial  ',
    icon: 'fa-brands fa-tiktok',
  },
  {
    link: 'https://www.facebook.com/SunParadiseLandPhuQuoc',
    icon: 'fa-brands fa-facebook-f',
  },
];
