/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_DETAIL_ACTIVITIES } from './slice';

export function* getDetailActivitites({ payload }: any) {
  try {
    const { SiteCode, slug, id } = payload;
    const { data } = yield call(
      BaseXHR.$get,
      `/api/explore/detail/${slug || id}`,
      undefined,
      {
        headers: {
          SiteCode,
        },
      },
    );

    yield put(GET_DETAIL_ACTIVITIES.success(data));
  } catch (err) {
    yield put(GET_DETAIL_ACTIVITIES.failure());
  }
}

const detailActivitiesSagas: any = [
  takeLatest(GET_DETAIL_ACTIVITIES.TRIGGER, getDetailActivitites),
];

export default detailActivitiesSagas;
