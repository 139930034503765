/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import { GET_ACTIVITIES, GET_BANNER_ACTIVITIES } from './slice';

export function* getActivities({ payload }: any) {
  try {
    const { key, SiteCode } = payload;
    const { data: activitiesData } = yield call(
      BaseXHR.$get,
      `/api/explore/listing?SiteCode=${SiteCode}&serviceType=entertainment&limit=10&category=${key}`,
      undefined,
      {},
    );

    const { data: categoryData } = yield call(
      BaseXHR.$get,
      `/api/category/child/entertainment?SiteCode=${SiteCode}`,
      undefined,
      {},
    );

    yield put(
      GET_ACTIVITIES.success({
        activities: activitiesData,
        category: categoryData,
      }),
    );
  } catch (err) {
    yield put(GET_ACTIVITIES.failure());
  }
}

export function* getBannerActivities({ payload }: any) {
  try {
    const { SiteCode } = payload;
    const { data } = yield call(
      BaseXHR.$get,
      `/api/banner/detail-by-key/other_activities`,
      undefined,
      {
        headers: {
          sitecode: SiteCode,
        },
      },
    );

    yield put(GET_BANNER_ACTIVITIES.success(data));
  } catch (err) {
    yield put(GET_BANNER_ACTIVITIES.failure());
  }
}

const activitiesSagas: any = [
  takeLatest(GET_ACTIVITIES.TRIGGER, getActivities),
  takeLatest(GET_BANNER_ACTIVITIES.TRIGGER, getBannerActivities),
];

export default activitiesSagas;
