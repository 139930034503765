/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Select } from 'antd';
import { useRouter } from 'next/router';
import LinkInOutApp from '@components/LinkInOutApp';
import { MENU_HEADER, MENU_HEADER_MOBI } from './constants';
import useIsPc from '@utils/hooks/useIsPc';
import { useDispatch, useSelector } from 'react-redux';
import { GET_CATEGORIES_DESTINATION } from '@containers/Destination/slice';
import { selectCategoriesDestination } from '@containers/Destination/selectors';

const { Option } = Select;

const locations = [
  {
    id: 1,
    name: 'Sun Paradise Land Phú Quốc',
    value: 'SunParadiseLandPhuQuoc',
  },
];

const Header = memo(() => {
  const dispatch = useDispatch();
  const tabBarRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const isPc = useIsPc();
  const router = useRouter();
  const [isScrolled, setIsScrolled] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showMenuMobi, setShowMenuMobi] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const park = router?.query?.park || '';

  const categoriesDestination = useSelector(selectCategoriesDestination);

  const MENU_HEADER_SUBCATEGORIES = useMemo(
    () =>
      MENU_HEADER.map(item =>
        item.link === 'diem-den'
          ? {
              ...item,
              children: categoriesDestination?.map(cate => ({
                link: cate.key,
                name: cate.name,
              })),
            }
          : item,
      ),
    [categoriesDestination],
  );

  const handleChange = (value: string) => {
    setSelectedLocation(value);
    router.push(`/${value}`);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        tabBarRef.current &&
        !tabBarRef.current.contains(event.target as Node) // Bỏ qua click vào tabBar
      ) {
        setShowMenuMobi(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setShowMenuMobi(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    if (router.query.park) {
      dispatch(GET_CATEGORIES_DESTINATION({ SiteCode: router.query.park }));
      const matchedLocation = locations.find(
        location => location.value === router.query.park,
      );
      if (matchedLocation) {
        setSelectedLocation(matchedLocation.value);
      } else {
        setSelectedLocation('');
      }
    }
  }, [router.query.park, locations]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY < lastScrollY) {
      setHideHeader(false);
    } else {
      setHideHeader(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);
  return isPc ? (
    <header
      className={`${isScrolled ? 'change' : ''} ${`header ${
        hideHeader ? 'hide' : ''
      }`} ${
        router.pathname !== '/' && router.pathname !== '/tin-tuc/[id]'
          ? 'header'
          : 'home'
      } ${router.pathname === '/[park]' ? 'white' : ''}`}
    >
      <div className="headerTop">
        <div className="container-fluid">
          <div className="--left">
            {router.asPath !== '/' && (
              <LinkInOutApp href="/" className="--back link">
                <>
                  <i className="fa-regular fa-arrow-left-long"></i> Quay lại
                </>
              </LinkInOutApp>
            )}
            <LinkInOutApp
              href="https://sungroup.com.vn/"
              className="--logo link"
            >
              <>
                <span>Hệ sinh thái</span>
                <img src="/static/img/image 113.png" alt="" />
              </>
            </LinkInOutApp>
          </div>
          <div className="--right">
            <div className="--download">
              <a className="--item" href="">
                <img src="/static/img/AppStore.png" alt="" />
              </a>
              <a className="--item" href="">
                <img src="/static/img/CHPlay.png" alt="" />
              </a>
            </div>
            {/* <div className="--money">
              <Select
                defaultValue="VNĐ"
                suffixIcon={<i className=" fa-regular fa-angle-down" />}
              >
                <Option value="VNĐ">VNĐ</Option>
              </Select>
            </div> */}
            <div className="--language">
              <Select
                defaultValue="vi"
                suffixIcon={<i className=" fa-regular fa-angle-down" />}
              >
                <Option value="vi">
                  <img src="/static/img/flagVn.png" alt="" />
                </Option>
              </Select>
            </div>
            {/* <a href="" className="--support link">
              Trợ giúp{' '}
            </a> */}
            {/* <div className="--log">
              <i className="fa-light fa-user"></i>
              <button className="--register">Đăng ký</button>/
              <button>Đăng nhập</button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="headerBot">
        <div
          className={`container-fluid ${
            router.pathname !== '/' && router.pathname !== '/tin-tuc/[id]'
              ? ''
              : 'hide'
          }`}
        >
          <div className="--left">
            <div className="--top">
              <div
                className={`--fil ${router?.pathname === '/' ? 'home' : ''}`}
              >
                <LinkInOutApp
                  href={`${
                    router?.asPath === '/' ||
                    router?.pathname === `/tin-tuc/[id]`
                      ? '/'
                      : `/${router?.query?.park}`
                  }`}
                  className="--logo"
                >
                  <img src="/static/img/logo.png" alt="" />
                </LinkInOutApp>
                {router.pathname === '/' && (
                  <div className="--listPsl">
                    {locations?.map((item, i) => (
                      <LinkInOutApp
                        className="--item link"
                        href={`/${item?.value}`}
                        key={i}
                      >
                        <> {item?.name}</>
                      </LinkInOutApp>
                    ))}
                  </div>
                )}
                {router.pathname !== '/' &&
                  router.pathname !== '/tin-tuc/[id]' && (
                    <div className="--filTabMenu">
                      <div className="--filSearch">
                        <div className="--filter">
                          <div className="--name">
                            <i className="fa-solid fa-location-dot" />
                            <Select
                              value={selectedLocation || 'Chọn địa điểm'}
                              onChange={handleChange}
                              suffixIcon={
                                <i className=" fa-regular fa-angle-down" />
                              }
                            >
                              {locations.map(location => (
                                <Option
                                  key={location.id}
                                  value={location.value}
                                >
                                  {location.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          {/* <div className="--search">
                            <Input
                              type="text"
                              placeholder="Tìm kiếm"
                              suffix={
                                <i className="fa-regular fa-magnifying-glass"></i> // Icon tìm kiếm
                              }
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="--tab">
                        {MENU_HEADER_SUBCATEGORIES?.map((item, i) => (
                          <div
                            onClick={() => {
                              if (!item.subMenu) {
                                router.push(
                                  `/${router?.query?.park}/${item?.link}`,
                                );
                              }
                            }}
                            className={`--item link ${
                              router.asPath ===
                              `/${router?.query?.park}/${item?.link}`
                                ? 'active'
                                : ''
                            }`}
                            key={i}
                          >
                            <>
                              <div className="itemMenu">
                                <i className={item?.icon} />
                                {item?.name}
                              </div>

                              {item?.subMenu && (
                                <div className="--subMenu">
                                  <div className="--listTab">
                                    {item?.children?.map(child => (
                                      <LinkInOutApp
                                        key={child.link}
                                        href={
                                          item.link === 'diem-den'
                                            ? `/${park}/${item.link}?key=${child.link}`
                                            : `/${park}/${child.link}`
                                        }
                                        className="--itemTab link"
                                      >
                                        <>
                                          {child.name}
                                          <i className="fa-regular fa-angle-right" />
                                        </>
                                      </LinkInOutApp>
                                    ))}
                                  </div>
                                  {/* <div className="--tabMenu">
                                  <div className="--listSubMenu">
                                    {listEat?.data?.map((item,i)=>(

                                    <LinkInOutApp
                                      href={``}
                                      className="--itemSub link"
                                      key={i}
                                    >
                                      <>
                                        <img
                                          src="/static/img/banner.jpeg"
                                          alt=""
                                        />
                                        <div className="--nameSub">
                                          Thị trấn Hoàng hôn - Sunset Town
                                        </div>
                                      </>
                                    </LinkInOutApp>
                                    ))}

                                  </div>
                                  <LinkInOutApp
                                    href={''}
                                    className="--viewAll textHover link"
                                  >
                                    <>
                                      Tất cả điểm đến
                                      <i className="fa-regular fa-angle-right"></i>
                                    </>
                                  </LinkInOutApp>
                                </div> */}
                                </div>
                              )}
                            </>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
              <div className="--gift">
                <LinkInOutApp href="" className="--item link btn btn-1">
                  <>
                    <img src="/static/img/gift.png" alt="" />
                    Phiếu quà tặng
                  </>
                </LinkInOutApp>
                <LinkInOutApp href="" className="--item link btn btn-2">
                  <>
                    <img src="/static/img/Vector.png" alt="" />
                    Tư vấn hành trình
                  </>
                </LinkInOutApp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  ) : (
    <div className={`headerMobi `}>
      <div className="--top">
        <div className="container-fluid">
          <div className="--logo">
            <img src="/static/img/image 113.png" alt="" />
          </div>
          <div className="--languageTab">
            {/* <Select
              defaultValue="vnd"
              suffixIcon={<i className=" fa-regular fa-angle-down" />}
            >
              <Option value="vnd">VNĐ</Option>
            </Select> */}
            <Select
              defaultValue="vi"
              suffixIcon={<i className=" fa-regular fa-angle-down" />}
            >
              <Option value="vi">
                <img src="/static/img/flagVn.png" alt="" />
              </Option>
            </Select>
            <div
              ref={tabBarRef}
              className={`--tabBar ${showMenuMobi ? 'active' : ''}`}
              onClick={() => setShowMenuMobi(!showMenuMobi)}
            >
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      {router.pathname !== '/' && router.pathname !== '/tin-tuc/[id]' && (
        <div className="--bot">
          <div className="container-fluid">
            <LinkInOutApp className="--logo" href="/">
              <img src="/static/img/logo.png" alt="" />
            </LinkInOutApp>

            <Select
              value={selectedLocation || 'Chọn địa điểm'}
              onChange={handleChange}
              suffixIcon={<i className=" fa-regular fa-angle-down" />}
            >
              {locations.map(location => (
                <Option key={location.id} value={location.value}>
                  {location.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}

      <div ref={menuRef} className={`menupage ${showMenuMobi ? 'active' : ''}`}>
        {router.pathname !== '/' && router.pathname !== '/tin-tuc/[id]' && (
          <div className="--listMenu">
            {MENU_HEADER_MOBI.map((item, i) => (
              <LinkInOutApp
                href={`/${router?.query?.park}/${item?.link}`}
                className="itemMenu link"
                key={i}
              >
                <>
                  <i className={item?.icon} /> {item?.name}
                </>
              </LinkInOutApp>
            ))}
          </div>
        )}

        <div className="--gift">
          <LinkInOutApp href="" className="--item link btn btn-1">
            <>
              <img src="/static/img/gift.png" alt="" />
              Phiếu quà tặng
            </>
          </LinkInOutApp>
          <LinkInOutApp href="" className="--item link btn btn-2">
            <>
              <img src="/static/img/Vector.png" alt="" />
              Tư vấn hành trình
            </>
          </LinkInOutApp>
        </div>
        <div className="--download">
          <a className="--item" href="">
            <img src="/static/img/AppStore.png" alt="" />
          </a>
          <a className="--item" href="">
            <img src="/static/img/CHPlay.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
});

export default Header;
