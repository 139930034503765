import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_DetailHotel, I_HotelList } from './typesIndex';

export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  detailHotel: null,
  hotelList: null,
};

export const GET_DETAIL_HOTEL = createRoutine('DetailHotel/getDetailHotel');
export const GET_HOTELLIST_DETAIL = createRoutine(
  'DetailHotel/getDetailHotelList',
);
const DetailHotelSlice = createSlice({
  name: 'detailHotel',
  initialState,
  extraReducers: {
    [HYDRATE]: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.detailAct,
    }),

    [GET_DETAIL_HOTEL.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_DETAIL_HOTEL.SUCCESS]: (
      state,
      action: PayloadAction<I_DetailHotel>,
    ) => {
      state.detailHotel = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_DETAIL_HOTEL.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },

    [GET_HOTELLIST_DETAIL.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_HOTELLIST_DETAIL.SUCCESS]: (
      state,
      action: PayloadAction<I_HotelList[]>,
    ) => {
      state.hotelList = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_HOTELLIST_DETAIL.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
});

export const { actions, reducer, name: sliceKey } = DetailHotelSlice;

export default reducer;
