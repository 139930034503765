/* eslint-disable @typescript-eslint/no-unused-vars */
import { call, put, takeLatest } from 'redux-saga/effects';
import { BaseXHR } from '@utils/axios';
import {
  GET_BANNER_DESTINATION,
  GET_CATEGORIES_DESTINATION,
  GET_DESTINATION,
} from './slice';

export function* getDestination({ payload }: any) {
  try {
    const { key, SiteCode, limit } = payload;
    const { data: destinationData } = yield call(
      BaseXHR.$get,
      `/api/explore/listing?SiteCode=${SiteCode}&page=1&limit=${
        limit || 10
      }&serviceType=${
        key ||
        'entertainment,restaurant,other_activities,event,tour,golf,nearby_attraction'
      }`,
      undefined,
      {},
    );

    yield put(
      GET_DESTINATION.success({
        destination: destinationData,
      }),
    );
  } catch (err) {
    yield put(GET_DESTINATION.failure());
  }
}

export function* getCategoriesDestination({ payload }: any) {
  try {
    const { SiteCode } = payload;

    const { data: categoryData } = yield call(
      BaseXHR.$get,
      `/api/category/listing?SiteCode=${SiteCode}`,
      undefined,
      {},
    );

    yield put(
      GET_CATEGORIES_DESTINATION.success({
        category: categoryData,
      }),
    );
  } catch (err) {
    yield put(GET_CATEGORIES_DESTINATION.failure());
  }
}

export function* getBannerDestination({ payload }: any) {
  try {
    const { SiteCode } = payload;
    const { data } = yield call(
      BaseXHR.$get,
      `/api/banner/detail-by-key/destination`,
      undefined,
      {
        headers: {
          sitecode: SiteCode,
        },
      },
    );

    yield put(GET_BANNER_DESTINATION.success(data));
  } catch (err) {
    yield put(GET_BANNER_DESTINATION.failure());
  }
}

const destinationSagas: any = [
  takeLatest(GET_DESTINATION.TRIGGER, getDestination),
  takeLatest(GET_CATEGORIES_DESTINATION.TRIGGER, getCategoriesDestination),
  takeLatest(GET_BANNER_DESTINATION.TRIGGER, getBannerDestination),
];

export default destinationSagas;
