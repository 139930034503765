import axios, {
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  InternalAxiosRequestConfig,
} from 'axios';
import Qs from 'qs';
import { DEFAULT_LANGUAGE_SELECTED, DEFAULT_PAGE_NAME, objectType } from 'type';
import { openNotificationWithIcon } from './helper';

const onSuccessInterceptorRequest = async (
  config: InternalAxiosRequestConfig<any>,
) => {
  const newConfig = { ...config };
  const language = config.headers.locale || DEFAULT_LANGUAGE_SELECTED;
  newConfig.url = `/${language}${newConfig.url}`;
  newConfig.params = { ...newConfig.params, platform: 'web' };
  newConfig.headers.Authorization = DEFAULT_PAGE_NAME;
  newConfig.paramsSerializer = (params: any) =>
    Qs.stringify(params, {
      arrayFormat: 'brackets',
    });
  return newConfig;
};
const onErrorInterceptorRequest = (error: AxiosError) => Promise.reject(error);

const onErrorInterceptorResponse = (error: AxiosError<any, any>) => {
  if (error.response && error.response.status) {
    if (error.response.status !== 200)
      error.response?.data?.msg &&
        openNotificationWithIcon(
          'error',
          error.response.data.msg,
          undefined,
          5,
        );
  }
  return Promise.reject(error);
};
const onSuccessInterceptorResponse = (response: AxiosResponse) => response;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';

const _axios: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || '',
  timeout: 120 * 1000,
  // withCredentials: true, // Check cross-site Access-Control
});

_axios.interceptors.request.use(
  onSuccessInterceptorRequest,
  onErrorInterceptorRequest,
);

_axios.interceptors.response.use(
  onSuccessInterceptorResponse,
  onErrorInterceptorResponse,
);

/**
 *
 * @NOTE primary methods axios
 *
 */
class AxiosXHRConstructor {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.$get = this.$get.bind(this);
    this.$getWithAuth = this.$getWithAuth.bind(this);
    this.$post = this.$post.bind(this);
    this.$put = this.$put.bind(this);
    this.$delete = this.$delete.bind(this);
  }

  public $getWithAuth(url: string, params?: objectType): AxiosPromise {
    return this.axiosInstance.get(url, params);
  }

  public $get(
    url: string,
    params?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.get(url, {
      params,
      ...config,
    });
  }

  public $post(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.post(url, data, config);
  }

  public $put(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.put(url, data, config);
  }

  public $delete(
    url: string,
    data?: objectType,
    config?: objectType,
  ): AxiosPromise {
    return this.axiosInstance.delete(url, {
      data,
      ...config,
    });
  }
}

export const BaseXHR = new AxiosXHRConstructor(_axios);

export default _axios;
