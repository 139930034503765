import activitiesSagas from '@containers/Activities/sagas';
import destinationSagas from '@containers/Destination/sagas';
import detailActivitiesSagas from '@containers/DetailActivities/sagas';
import detailDestinationSagas from '@containers/DetailDestination/sagas';
import detailHotelSagas from '@containers/DetailHotel/sagas';
import detailNewsSagas from '@containers/DetailNews/sagas';
import detailParkSagas from '@containers/DetailPark/sagas';
import eatSagas from '@containers/Eat/sagas';
import homeSagas from '@containers/Home/sagas';
import hotelSagas from '@containers/Hotel/saga';
import playSagas from '@containers/Play/sagas';
import shopingSagas from '@containers/Shoping/sagas';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  try {
    yield all([
      ...homeSagas,
      ...detailParkSagas,
      ...activitiesSagas,
      ...hotelSagas,
      ...detailActivitiesSagas,
      ...detailDestinationSagas,
      ...detailHotelSagas,
      ...detailNewsSagas,
      ...destinationSagas,
      ...eatSagas,
      ...playSagas,
      ...shopingSagas,
    ]);
  } catch {}
}
