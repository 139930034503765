import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
:root {
  --color-1: #1d7570;
  --color-2: #fdb913;
  --color-3: #80513c;
  --color-4:#f16f24;
  --bg-gradient:linear-gradient(270deg, #f16f24 4.72%, #ef562d 97.4%);
  --transition-all: all 0.3s ease-in-out;
  --transition-all-2: all 0.5s ease-in-out;
  --font-body: "Be Vietnam Pro", sans-serif;
  --font-playfair: "Playfair Display", serif;
  --width-container:1270px;
  @media(max-width: 1439px){
    --width-container:1100px;
  }
  @media(max-width: 1279px){
    --width-container:1000px;
  }
  @media(max-width: 1100px){
    --width-container:900px;
  }
  @media(max-width: 769px){
    --width-container: calc(100vw - 30px);
  }
}
@keyframes zoomOut {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
     100% {
    transform: scale(1);
  }
}

body {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-body);
  margin:0;
  line-height: normal;
  @media(max-width:1279px){
    font-size: 13px;
  }
}
.container-fluid {
  padding: 0;
  width: var(--width-container);
  display: block;
  margin: 0 auto;
  &.left{
    width:  calc(100vw - (100vw - var(--width-container)) / 2);
    margin: 0 0 0 auto;
  }
  &.right{
    width:  calc(100vw - (100vw - var(--width-container)) / 2);
    margin: 0 auto 0 0;
  }
  @media(max-width: 769px){
    &.left,&.right{
      width: var(--width-container);
      margin: 0 auto;
    }
  }
}
.link {
  text-decoration: none;
}
.segTitle,
.subTitle {
  font-weight: 600;
  line-height: 50px;
  font-size: 42px;
  @media(max-width:1439px){
    font-size: 36px;
    line-height: 44px;
  }
  @media(max-width: 1279px){
    font-size: 32px;
    line-height: 40px;
  }
}
.titleFrame {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  @media(max-width: 1279px){
    font-size: 28px;
    line-height: 34px;
  }
  @media(max-width: 640px){
    font-size: 24px;
    line-height: 30px;
  }
}
img {
  object-fit: cover;
}
.slick-dots {
  list-style: none;
  padding-left: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  gap: 6px;
  position: relative;
  bottom: unset;
li {
  width: 10px;
  height: 10px;
  overflow: hidden;
  border-radius: 50%;
  background: #e5e5e5;
  margin: 0;
  &.slick-active {
    background: var(--color-2);
  }
}
button {
  opacity: 0;
}
}
.imgHover {
  overflow: hidden;
  img {
    transition: var(--transition-all-2);
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
  @media(max-width: 769px){
    &:hover{
      img{
        transform: scale(1);
      }
    }
  }
}
.imgHover1 {
  .--img {
    overflow: hidden;
    img {
      transition: var(--transition-all);
    }
  }
  &:hover {
    .--img {
      img {
        transform: scale(1.2);
      }
    }
  }
  @media(max-width: 769px){
    &:hover{
      .--img{
        img{
          transform: scale(1);
        }
      }
    }
  }
}
.ant-select-dropdown {
  width:max-content !important;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.05);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
.cardNote{
  background: #1d7570;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  color: #fff;
  height: max-content;
  padding: 24px;
  .--price {
    font-size: 26px;
    font-weight: 600;
  }
  .--note {
    margin-top: 8px;
  }
  article {
    margin-top: 20px;
  }
  .--viewDetail {
    text-align: center;
    color: #fff;
    background: #ffffff29;
    height: 52px;
    margin-top: 20px;
  }
  @media(max-width: 1439px){
    .--price{
      font-size: 24px;
    }
  }
}
.PageBanner {
  padding-top: 154px;
  .container-fluid {
    display: grid;
    grid-template-columns: auto 65%;
    gap: 80px;
  }
  .--txt {
    display: flex;
    flex-flow: column;
    gap: 32px;
    padding-bottom: 32px;
  }
  .segTitle {
    margin: auto 0 0 0;
  }
  .--img {
    aspect-ratio: 11/6;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  article {
  }
  .--tag {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 8px;
    .--item {
      color: #464646;
      font-size: 18px;
      transition: var(--transition-all);
      text-decoration: underline;
      &:hover {
        color: var(--color-4);
      }
    }
  }
  @media(max-width: 1439px){
    .container-fluid{
      grid-template-columns: auto 60%;
      grid-gap:40px;
    }
    .--tag{
      .--item{
        font-size: 16px;
      }
    }
  }
  @media(max-width: 769px){
    .container-fluid{
      display: flex;
      flex-flow: column;
      gap: 24px;
    }
    .--txt{
      gap: 12px;
      padding-bottom: 0;
    }
    .--tag{
      .--item{
        font-size: 14px;
      }
    }
  }

}
.TabPage {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 32px;
  .--item {
    display: flex;
    gap: 8px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
    transition: var(--transition-all);
    &.active,
    &:hover {
      background: var(--color-1);
      color: #fff;
    }
  }
  @media(max-width: 769px){
    margin-top: 20px;
    overflow-x: auto;
    flex-wrap: nowrap;
    .--item{
      flex: 0 0 auto;
    }
  }
}
.PageAbout {
  padding: 80px 0;
  .container-fluid {
    display: grid;
    grid-template-columns: auto 380px;
    gap: 24px;
  }
  .--left {
    .--item {
      border-bottom: 1px solid #0000001a;
    }
    article {
      border-top: 1px solid #0000001a;
      padding: 16px 0 32px;
    }
    .--btnShow {
      width: 100%;
      background: transparent;
      border: none;
      font-size: 20px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      font-weight: 700;
      transition: var(--transition-all);
      &:hover {
        background: #eeeeee59;
      }
      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
      &.collapsed {
        .--icon {
          .fa-chevron-down {
            display: block;
          }

          .fa-chevron-up {
            display: none;
          }
        }
      }
    }
    i {
      font-size: 14px;
      &.fa-chevron-down {
        display: none;
        color: #9b9b9b;
      }
      &.fa-chevron-up {
        display: block;
        color: var(--color-7);
      }
    }
  }
}
.PageDestination {
  padding: 80px 0;
  .--title {
    display: flex;
    gap: 20px;
    .--vote {
      font-size: 20px;
      background: var(--color-1);
      border-radius: 8px;
      padding: 2px 8px;
      line-height: 1;
      color: #fff;
      display: flex;
      align-items: center;
      font-weight: 800;
      gap: 4px;
      i {
        font-size: 16px;
      }
    }
  }
  .container-fluid {
    display: flex;
    flex-flow: column;
  }
  .--top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .arrow {
    display: flex;
    gap: 8px;
    .--arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #9f98983d;
      border-radius: 50%;
      width: 62px;
      cursor: pointer;
      height: 62px;
      transition: var(--transition-all);
      &:hover {
        color: #fff;
        transform: scale(0.9);
        background: var(--bg-gradient);
      }
    }
  }
  .--note {
    display: flex;
    gap: 10px;
    margin-top: 8px;
    .--viewMore {
      color: var(--color-1);
      display: flex;
      align-items: center;
      gap: 4px;
      i {
        font-size: 12px;
      }
    }
  }
  .--listDestination {
    margin-top: 24px;
    &.litle {
      display: flex;
      gap: 24px;
      .itemDetination {
        width: calc(50% - 12px);
        padding: 40px 200px 40px 32px;
        background: #f5fcff;
        flex: 0 0 auto;
        position: relative;
        min-height: 360px;
      }
    }
  }
  .slick-slide {
    margin: 0 12px;
    padding: 40px 200px 40px 32px;
    position: relative;
    background: #f5fcff;
    min-height: 360px;
  }
  .slick-list {
    margin: 0 -12px;
  }
  .slick-track {
    display: flex;
  }
  .itemDetination {
    .--bot {
      display: flex;
      justify-content: space-between;
      align-items: end;
    }
    .--name {
      font-weight: 600;
    }
    .--infoAll {
      display: flex;
      gap: 16px;
    }
    .--position {
      color: #919191;
    }
    .--avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .--img {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 120px;
      transition: var(--transition-all-2);
      z-index: 1;
      &:hover {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .--txt {
      display: flex;
      flex-flow: column;
      gap: 20px;
      height: 100%;
    }
    .--bot {
      margin: auto 0 0 0;
    }
    .--star {
      display: flex;
      gap: 2px;
      i {
        font-size: 12px;
        background: var(--color-1);
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
    article {
      line-height: 26px;
    }
  }
  @media (max-width: 1279px) {
    padding: 60px 0;
  }
  @media (max-width: 769px) {
    .--listDestination {
      &.litle {
        .itemDetination {
          width: 100%;
          padding: 20px;
          flex-flow: column;
          padding: 0;
          box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.08);
        }
      }
      .--icon {
        img {
          width: 60px;
        }
      }
      .itemDetination {
        .--img {
          position: relative;
          top: unset;
          right: unset;
          width: 100%;
          height: 180px;
        }
        .--txt {
          height: max-content;
          padding: 16px;
        }
      }
    }
    .slick-list{
      margin: 0;
    }
    .arrow{
      margin-top: 20px;
    }
    .slick-slide{
      padding: 16px;
      margin: 0;
      width: 300px;
    }

  }
}
#nprogress .bar {
  background: #ff5733; 
  height: 4px;
}
.textHover{
  position: relative;
  &::after{
    content: '';
    position: absolute;
    border-bottom:1px solid var(--color-1) ;
    width: 0;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    transition: var(--transition-all);
  }
  &:hover{
    &::after{
      width: 100%;
    }
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: var(--transition-all);
  &.change{
    box-shadow: 0px 4px 40px rgb(0 0 0 / 20%);
    .headerBot{
      background: #FFF;
      padding: 6px 0 0 0;
      .--logo{
        img{
          height: 40px;
        }
      }
  
      .--filSearch{
        height: 40px;
      }
    }
    &.white{
      .headerBot{
        .--tab{
          .--item{
            .itemMenu{
              color: #919191;
            }
          }
        }
      }
    }
    &.home{
      .headerBot{
        padding: 6px 0;
      }
    }
  } 
  &.header{
    .headerBot {
      .--tab {
        .--item{
          .itemMenu{
            color: #919191;
          }
        }
      }
    }
  }
  &.white{
    .headerBot{
      .--tab{
        .--item{
          .itemMenu{
            color: #FFF;
          }
        }
      }
    }
  }
  &.hide{
    transform: translateY(-100%);
  }
  .headerTop {
    background: var(--color-1);
    padding: 6px 0;
    font-size: 12px;
    .container-fluid {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .--left{
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .--back{
      height: 24px;
      background: #FFFFFF1A;
      display: flex;
      align-items: center;
      padding: 0 8px;
      color: #FFF;
      font-size: 12px;
      border-radius: 4px;
      gap: 6px;
      transition: var(--transition-all);
      &:hover{
        background: var(--color-4);
        transform: scale(.95);
      }
    }
    .--logo {
      color: #fff;
      display: flex;
      align-items: center;
      gap: 16px;
      img {
        height: 30px;
      }
    }
    .ant-select-arrow{
      color: #FFF;
      display: flex;
      align-items: center;
      height: 100%;
      top: 0;
      transform: none;
      margin-top: 2px;
    }
    .ant-select-selection-item{
      color: #FFF !important;
    }
    .ant-select-selector{
      background: transparent !important;
      border: none !important;
      color: #FFF !important;
      padding: 0 6px 0 0  !important;
    } 
    .ant-select{
      &:focus,&:focus-visible,&:focus-visible{
        outline: none;
        box-shadow: none;
      }
    }
    .--download {
      display: flex;
      align-items: center;
      gap: 6px;
      .--item {
        display: flex;
        border-radius: 6px;
        overflow: hidden;
      }
      img {
        height: 30px;
      }
    }
    .--right {
      display: flex;
      align-items: center;
      gap: 24px;
    }
    .--support {
      color: #fff;
    }
    .--log {
      color: #fff;
      i {
        margin-right: 6px;
      }
      button {
        background: transparent;
        padding: 0;
        border: none;
        color: #fff;
      }
    }
  }
  .headerBot {
    padding: 12px 0 0 0;
    transition: var(--transition-all);
    .--logo {
      img {
        height: 54px;
        transition: var(--transition-all);
      }
    }
    .--left{
      width: 100%;
    }
    .--fil{
      display: flex;
      gap: 16px;
      &.home{
        align-items: center;
      }
    }
    .--listPsl{
      display: flex;
      align-items: center;
      margin-left: 60px;
      gap: 32px;
      font-family: var(--font-playfair);
      .--item{
        color: #000;
        font-weight: 700;
        transition: var(--transition-all);
        &:hover{
          color: var(--color-4);
        }
      }
    }
    .--gift {
      display: flex;
      gap: 16px;
      .--item {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0px 20px 0px 16px;
        gap: 8px;
        height: 40px;
        border-radius: 6px;
      }
    }
    .container-fluid{
      border-bottom:1px solid #FFF ;
      display: flex;
      justify-content: space-between;
      &.hide{
        border-bottom:none ;
      }
    }
    .--top {
      display: flex;
      gap: 16px;
      justify-content: space-between;
    }
    .--filter{
      gap: 12px;
      border: 1px solid #ededed;
      background: #fff;
      display: flex;
      padding: 4px;
      border-radius: 4px;
      align-items: center;
      width: max-content;
    }
    .--filSearch{
      height: 54px;
    }
    .--tab{
      display: flex;
      gap: 32px;
      .--item{
        position: relative;
        .itemMenu{
          gap: 6px;
          cursor: pointer;
          font-size: 13px;
          color: #FFF;
          height: 44px;
          transition: var(--transition-all);
          display: flex;
          align-items: center;
        }
        &:hover{
          .itemMenu{
            color: var(--color-4) !important;
          }
          .--subMenu{
            top: 100%;
            opacity: 1;
            pointer-events: auto;
          }
        }
        &.active{
         .itemMenu{
          color: var(--color-4);
          border-bottom:4px solid var(--color-4);
         }
        }
      }
      .--listTab{
        width: 240px;
        flex: 0 0 auto;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
          background: #888;
        }
        .--itemTab{
          padding:12px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          transition: var(--transition-all);
          color: #000;
          &:hover{
            background: #E9F2F1;
            color: var(--color-1);
          }
        }
      }
      .--tabMenu{
        width: 340px;
        padding: 20px 0;
        flex: 0 0 auto;
        background: #E9F2F1;
        box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.08);
      }
      .--subMenu{
        position: absolute;
        top: calc(100% + 20px);
        left: 0;
        background: #FFF;
        filter: drop-shadow(0px 4px 44px rgba(0, 0, 0, 0.36));
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        opacity: 0;
        transition: var(--transition-all);
        pointer-events: none;
      }  
      .--itemSub{
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 20px;
        border-bottom:1px solid #0000001A ;
        &:last-child{
          border-bottom:none ;
        }
        &:hover{
          .--nameSub{
            color: var(--color-4);
          }
        }
        img{
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        .--nameSub{
          transition: var(--transition-all);
          font-size: 16px;
          color: #000000B2;
        
        }
      }
      .--listSubMenu{
      }
      .--viewAll{
        color: var(--color-1);
        width: max-content;
        margin-left: 20px;
        display: flex;
        gap: 6px;
        align-items: center;
      }
    }
    .--search {
      position: relative;

      .ant-input-affix-wrapper {
        padding: 0;
        border: none;
        height: 100%;
        border: none;
        width: 360px;
        &:focus,&:focus-within,&:focus-visible{
          outline: none;
          box-shadow: none !important;
        }
      }
    }
    .--name{
      position: relative;
      background: #1D75701A;
      border-radius: 4px;
      color: var(--color-1);
      .fa-location-dot{
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        left: 12px;
        pointer-events: none;
        z-index: 1;
      }
    }
    .ant-select-selection-item{
      color: var(--color-1);
    }
    .ant-select-selector{
      padding: 0 20px 0 32px !important ;
      border: none  !important;
      background: transparent !important;
    }
  }
  @media(max-width:1439px){
    .headerBot{
      .--tab {
        .--listTab {
          .--itemTab{
            font-size: 15px;
            padding: 10px 16px;
          }
        }
      }
    }
  }
  @media(max-width: 1279px){
    &.change{
      .--tab{
        .--item{
          .itemMenu{
            height: 36px;
          }
        }
      }
    }
    .headerBot{
      .--search {
        .ant-input-affix-wrapper{
          width: 300px;
        }
      }
      
    }
  }
}
.headerMobi{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.08);
  .--top{
    background: var(--color-1);
    height: 56px;
    display: flex;
    align-items: center;
    .container-fluid{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .ant-select-selector{
      background: none !important;
      padding: 0 8px 0 0 !important;
      border: none !important;
    }
    .ant-select-selection-item{
      color: #FFF;
    }
    .ant-select-arrow{
      color: #FFF;
    }
    .--languageTab{
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .--tabBar{
      display: flex;
      flex-flow: column;
      gap: 8px;
      p{
        width: 34px;
        height: 2px ;
        margin-bottom: 0;
        transition: var(--transition-all-2);
        background: #FFF;
      }
      &.active{
        p{

          &:first-child{
            transform:translate(4px,14px) rotate(45deg);
          }
          &:nth-child(2){
            width: 0;
          }
          &:nth-child(3){
            transform: translate(4px,-5px) rotate(-45deg);
          }
        }
      }
    }
    .--logo{
      img{
        height: 40px;
      }
    }
  }
  .--bot{
    height: 54px;
    background: #FFF;
    display: flex;
    align-items: center;
    .container-fluid{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .--logo{
      img{
        height: 40px ;
      }
    }
  }
  .menupage{
    position: absolute;
    top: 100%;
    padding: 0 15px;
    right: 0;
    width: 100vw;
    height: max-content;
    background: #FFF;
    max-height: 0;
    transition: var(--transition-all-2);
    overflow: hidden;
    &.active{
      max-height: 500px;
      border-bottom:1px solid var(--color-1) ;
    }
    .--listMenu{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 8px;
      padding: 20px 0 10px;
    }
    .itemMenu{
      color: #000;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      line-height: normal;
      gap: 6px;
      align-items: center;
    }
 
    .--gift{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 12px;
      padding: 10px 0;
      .--item{
        color: #FFF;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 0 12px;
        height: 36px;
        justify-content: center;
        border-radius: 6px;
        padding: 0;
        font-size: 13px;
      }
    }
    .--download{
      grid-gap: 12px;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      padding: 0 0 20px;
      .--item{
        border-radius: 6px;
        overflow: hidden;
        img{
          width: 100%;
        }
      }
    }
  }

}
article{
  img{
    max-width: 100%;
  }
  table{
    td, tfoot, th, thead, tr{
      border: 1px solid ;
    }
  }
}
.mapContainer{
  .gm-style-iw-a{
    transform: translate(0,-28px);
  }
  .gm-style-iw-c{
    width: 260px;
    button{
      display: none !important;
    }
  }
  .--star{
    color: #FFBB00;
  }
  .--name{
    font-size: 16px;
    margin-top: 4px;
    font-weight: 600;
  }
}
.btn{
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: #FFF;
  border-radius: 6px;
  font-weight:700;
  font-size: 14px;
  text-align:center;
  justify-content:center;
  transition: var(--transition-all);
  &.btn-1{
    background: linear-gradient(270deg, #F16F24 4.72%, #EF562D 97.4%);
  }
  &.btn-2{
    background: var(--color-1);
  }
  &:hover{
    transform: scale(.95);
    color: #FFF;
  }
}
footer {
  .footerTop {
    position: relative;
    .--banner {
      width: 100%;
    }
    .--cloud {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
  .footerCenter {
    padding: 60px 0;
    .container-fluid {
      display: flex;
      flex-flow: column;
      gap: 48px;
    }
    .--name {
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
    }
    .--item {
      display: flex;
      flex-flow: column;
      gap: 20px;
    }
    .--listLink {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
    .--itemLink {
      color: #5c5c5c;
      border-right: 1px solid #0000001a;
      padding-right: 8px;
      &:hover {
        color: var(--color-4);
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }
  .footerBot {
    background: #101828;
    .--top {
      .container-fluid {
        padding: 60px 0;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 52px;
        .--logo {
          img {
            height: 60px;
          }
        }
        .--title {
          margin-top: 32px;
          color: #fff;
          font-size: 20px;
        }
        .--follow {
          margin-top: 24px;
          span {
            color: #ffffff80;
          }
        }
        .--listSocial {
          display: flex;
          gap: 8px;
          margin-top: 16px;
        }
        .--itemSocial {
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          color: #fff;
          border: 1px solid #fff;
          justify-content: center;
          border-radius: 50%;
          transition: var(--transition-all);
          &:hover{
            transform: scale(.9);
            background: #FFF;
            color: var(--color-4);
          }
        }
        .--listMenu {
          margin-top: 24px;
          display: flex;
          flex-flow: column;
          gap: 16px;
        }
        .--itemMenu {
          color: #ffffff99;
          transition: var(--transition-all);
          &:hover {
            color: #fff;
          }
        }
        .--name {
          font-size: 18px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
    .--bottom {
      border-top: 1px solid #ffffff1a;
      .container-fluid {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px 0;
      }
      .--copy {
        color: #ffffff99;
      }
      .--privacy {
        .--item {
          color: #ffffff99;
          border-right: 1px solid #ffffff7a;
          padding-right: 10px;
          margin-right: 10px;
          transition: var(--transition-all);
          &:hover {
            color: #fff;
          }
          &:last-child {
            border-right: none;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
  @media(max-width: 769px){
    .footerCenter{
      .container-fluid{
        gap: 24px;
      }
      .--item{
        gap: 12px;
      }
    }
    .footerBot{
      .--top{
        .container-fluid{
          display: flex;
          flex-wrap: wrap;
          column-gap: 8px;
          row-gap: 20px;
          .--listSocial{
            margin-top: 8px;
          }
          .--listMenu{
            margin-top: 12px;
            gap: 4px;
          }
        }
      }
      .--col{
        &:first-child{
          width: 100%;
          .--title{
            margin-top: 20px;
            line-height: 1;
          }
          .--follow{
            margin-top: 12px;
          }
        }
        &:nth-child(n+2){
          width: calc(50% - 4px);
        }
      }
      .--bottom{
        .container-fluid{
          flex-flow: column-reverse;
          gap: 12px;
          line-height:1;
          padding: 20px 0;
        }
      }
    }
  }
}
.itemPlay{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-flow: column;
  .--img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 38.18%, rgba(0, 0, 0, 0.5) 100%);
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  .--txt{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    height: 100%;
    padding: 16px;
    color: #FFF;
  }
  .--cate{
    padding: 4px 12px;
    background: linear-gradient(270deg, #F16F24 4.72%, #EF562D 97.4%);
    border-radius: 6px;
    width: max-content;
  }
  .--infoAll{
    display: flex;
    justify-content: space-between;
    gap:20px;
    
  }
  .--name{
    font-size: 18px;
    font-weight: 700;
  }
  .--info{
    display: flex;
    flex-flow:column;
    gap: 8px;
  }
  .--location{
    display: flex;
    gap: 4px;
  }
  .--viewDetail{
    padding: 0 16px;
    flex :0 0 auto;
    color: #FFF;
    border: 1px solid #FFF;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    border-radius: 6px;
    height: 38px;
    display: flex;
    align-items: center;
    transition: var(--transition-all);
    &:hover{
      background: linear-gradient(270deg, #f16f24 4.72%, #ef562d 97.4%);
      border: 1px solid var(--color-4) ;
    }
  }
  @media(max-width: 1279px){
    .--name{
      font-size: 16px;
    }
    .--viewDetail{
      height: 32px;
      padding: 0 12px;
    }
  }
}
.dialogEat{
  width: 840px !important;
  .ant-modal-content{
    overflow: hidden;
    padding: 0 !important;
  }
  .ant-modal-body{
    display: flex;
  }
  ul{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
    li{
      display: flex;
      align-items: center;
      gap: 8px;
    }
    i{
      width: 20px;
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
    }
  }
  article{
    margin-top: 20px;
    max-height: 150px;
    overflow-y: auto;
    width: calc(100% + 20px);
    padding-right: 20px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
  .--viewMap{
    color: var(--color-1);
    width: max-content;
    margin-top: 4px;
    margin-left: 28px;
  }
  .--button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
  .--info{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  .nextSlider{
    margin-top:auto ;
    .slick-track{
      display: flex !important;
    }
  
    .slick-slide {
      margin-right:  8px;
      width: 180px;;
      >div{
        width: 100%;
        height: max-content;
        display: flex;
      }
    }
    .--thumb{
      width: 100%;
      aspect-ratio: 4/3;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  .--listImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .slider{
    height: auto;
    width: 420px;
    position: relative;
    flex: 0 0 auto;
    min-height: 500px;
    img{
      height: 100%;
      width: 100%;
    }
    .slick-track{
      display: flex;
    }
    .slick-list{
      height: 100%;
    }
    .slick-track{
      height: 100%;
    }
    .slick-slider{
      height: 100%;
    }
    .--img{
      height: 100%;
    }
    .slick-slide{
      >div{
        height: 100%;
      }
    }
  }
  .arrow{
    display: flex;

    gap: 8px;
  }
  .--arrow{
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #FFF;
    border: 1px dashed #636363;
    color: #636363;
    cursor: pointer;
    transition: var(--transition-all);
    &:hover{
      background: var(--color-4);
      color: #FFF;
    }


  }

  .--name{
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 32px;
  }
  .--txt{
    padding: 40px;
    display: flex;
    flex-flow: column;
  }
  .--info{
    width: 420px;
  }
  .--bookTicket{
    width: max-content;
    margin-top: auto ;
    height: 44px;
    padding: 0 32px;
  }
  @media(max-width: 769px){
    .ant-modal-body{
      flex-flow: column;
    }
    article{
      overflow: unset;
      max-height: unset;
      width: 100%;
      padding-right: 0;
    }
    .--listImage{
      position: relative;
      top: unset;
      left: unset;
    }
    .--info{
      width: 100%;
    }
    .nextSlider {
      display: none;
    }
    .slider{
      width: 100%;
      height: 220px;
      min-height: unset;
    }
    .--img{
      width: 100%;
    }
    .--bookTicket{
      margin-top: 16px;
    }
    .--txt{
      padding: 20px;
    }
  }
}
.mapHotel {
  width: 100%;
  height: 100%;
  min-height: 500px;
  .gm-style-iw {
    padding-left: 0;
    width: 280px;
    max-width: unset !important;
  }
  .gm-style-iw-a {
    transform: translate(0, -20px);
  }
  .gm-ui-hover-effect {
    position: absolute !important;
    top: 0;
    right: 0;
    filter: brightness(100) invert(1);
  }
  .gm-style-iw-ch {
    display: none;
  }
  .gm-style-iw-d {
    padding: 0;
    overflow: unset !important;
  }
  .--star {
    color: #ffbb00;
    display: flex;
    gap: 4px;
  }
  .--name {
    font-size: 16px;
    margin-top: 8px;
    font-weight: 700;
  }
  .--location {
    display: flex;
    gap: 8px;
    color: #464646;
    margin-top: 4px;
  }
  .--price {
    margin-top: 16px;
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .--txt {
    padding: 16px;
  }
  .--img {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.noData{
  margin-top: 24px;
  font-size: 24px;
  font-weight: 500;
}

`;

export const NotFoundWrapper = styled.div`
  .container-fluid {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 250px;
    flex-flow: column;
  }
  .--icon {
    margin-bottom: 1.5rem;
    img {
      width: 250px;
    }
  }
  .--title {
    font-size: 24px;
    font-family: var(--font-title);
    font-weight: bold;
  }
  .--subTitle {
    font-size: 13px;
    margin-bottom: 1rem;
    a {
      color: var(--color-1);
    }
  }
  .backtohome {
    background: var(--color-3);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    color: #000;
    border-radius: 8px;
    margin-top: 1rem;
    transition: all 0.15s ease;
    &:hover {
      color: var(--color-3) !important;
    }
    &::after {
      border-radius: 8px;
      background: #fff;
      border: 1px solid var(--color-3);
    }
  }
`;
