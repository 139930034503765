import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { createRoutine } from 'redux-saga-routines';
import { ContainerState, I_DetailActivities } from './typesIndex';

export const initialState: ContainerState = {
  loading: false,
  success: false,
  error: false,
  detailActivities: null,
};

export const GET_DETAIL_ACTIVITIES = createRoutine(
  'DetailActivities/getDetailActivities',
);

const DetailActivitiesSlice = createSlice({
  name: 'detailActivities',
  initialState,
  extraReducers: {
    [HYDRATE]: (state, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload.detailAct,
    }),

    [GET_DETAIL_ACTIVITIES.TRIGGER]: state => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GET_DETAIL_ACTIVITIES.SUCCESS]: (
      state,
      action: PayloadAction<I_DetailActivities>,
    ) => {
      state.detailActivities = action.payload;
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    [GET_DETAIL_ACTIVITIES.FAILURE]: state => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
  reducers: {
    resetStatusPage(state) {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.success = initialState.success;
    },
  },
});

export const { actions, reducer, name: sliceKey } = DetailActivitiesSlice;

export default reducer;
